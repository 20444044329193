
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import EntityModelVersionSelector from '@/components/selector/entity-model-version-selector.vue'
import webApiProjectInfoApi, {
  WebApiProjectInfo
} from '@/api/web-api-project-info'
import { getterProjectId, getterProjectMemberRight } from '@/store'
import projectGitInfoApi, {
  GitRepositoryType
} from '@/api/code-generate/project-gitinfo'
import { Form } from 'view-design'
import codeGenerateTaskApi, {
  TaskStatus
} from '@/api/code-generate/code-generate-task'
import XtermVue from '@/components/xterm-vue/XtermVue.vue'

@Component({
  name: 'generateCode',
  components: { EntityModelVersionSelector, XtermVue }
})
export default class GenerateCode extends Vue {
  branchs: string[] = []
  frontBranchs: string[] = []
  appBranchs: string[] = []

  // 当前选择的版本id
  entityModelVersionId = -1

  // 当前项目信息
  projectInfo: WebApiProjectInfo = {}

  // 代码生成任务
  codeTaskInstance: any

  // 任务中
  doing = false

  /**
   * 取得项目id
   */
  get projectId () {
    return getterProjectId()
  }

  /**
   * 可以进行代码生成
   */
  get canGenger () {
    const userRight = getterProjectMemberRight()
    if (!userRight) return false
    console.log('用户权限', userRight)

    return userRight.codegener
  }

  // formData
  formData = {
    projectId: getterProjectId(),
    versionId: undefined,
    branch: '',
    frontBranch: '',
    appBranch: '',
    gitUrl: ''
  }

  // 当前的任务状态
  taskStatus = {
    percent: 0,
    status: 'normal'
  }

  rules = {
    versionId: [
      {
        required: true,
        type: 'number',
        message: '请选择项目版本',
        trigger: 'blur'
      }
    ],
    branch: [
      {
        required: true,
        type: 'string',
        message: '请填写代码生成所在的分支',
        trigger: 'blur'
      },
      { validator: this.checkProjectBranValid, trigger: 'blur' }
    ],
    frontBranch: [
      {
        required: true,
        type: 'string',
        message: '请填写代码生成所在的分支',
        trigger: 'blur'
      },
      { validator: this.checkProjectBranValid, trigger: 'blur' }
    ],
    appBranch: [
      {
        required: true,
        type: 'string',
        message: '请填写代码生成所在的分支',
        trigger: 'blur'
      },
      { validator: this.checkProjectBranValid, trigger: 'blur' }
    ],
    gitUrl: [
      {
        required: true,
        type: 'string',
        message: '项目没有设置giturl地址',
        trigger: 'blur'
      }
    ],
    frontGitUrl: [
      {
        required: true,
        type: 'string',
        message: '项目没有设置frontGitUrl地址',
        trigger: 'blur'
      }
    ]
  }

  async created () {
    try {
      this.projectInfo =
        (await webApiProjectInfoApi.getInfoByProjectId(this.projectId!)).data ||
        {}
      this.formData.gitUrl = this.projectInfo?.gitUrl || ''
      this.branchs =
        (await projectGitInfoApi.getProjectBranches(this.projectId!)).data || []
      if (this.projectInfo.frontGitUrl) {
        this.frontBranchs =
          (await projectGitInfoApi.getProjectBranches(this.projectId!, 'front'))
            .data || []
      }
      if (this.projectInfo.appGitUrl) {
        this.appBranchs =
          (await projectGitInfoApi.getProjectBranches(this.projectId!, 'app'))
            .data || []
      }
    } catch (err) {
      this.$Message.error('取得当前项目信息失败:' + err)
    }
  }

  /**
   * 请求代码生成
   */
  requestGenerateCode () {
    this.doing = true
    const form = this.$refs.form as Form
    form.validate(valid => {
      if (!valid) {
        this.$Message.error('数据校验错误')
        this.doing = false
      } else {
        // 执行数据操作
        const xterm = this.$refs.xterm as XtermVue
        this.codeTaskInstance = codeGenerateTaskApi.requestCodeGenerTask(
          {
            projectId: this.formData.projectId!,
            versionId: this.formData.versionId!,
            branch: this.formData.branch!,
            frontBranch: this.formData.frontBranch!
          },
          (data?: TaskStatus) => {
            if (data) {
              xterm.addMessage(data.message)
              this.taskStatus.percent = data.finish
                ? 100
                : Math.round((data.curStep / data.totalStep) * 10) / 10
              this.taskStatus.status = data.error
                ? 'wrong'
                : data.finish
                  ? 'success'
                  : 'active'
            }
          },
          () => {
            xterm.addMessage('联结上代码生成服务器')
            // this.doing = true
          },
          err => {
            xterm.addMessage(err.message)
            this.doing = false
          }
        )
      }
    })
  }

  /**
   * 检查分支是否合法
   */
  checkProjectBranValid (
    rule: any,
    value: string,
    callback: (err?: Error) => void
  ) {
    let field: GitRepositoryType
    switch (rule.field) {
      default:
      case 'branch':
        field = 'back'
        break
      case 'frontBranch':
        field = 'front'
        break
      case 'appBranch':
        field = 'app'
        break
    }

    projectGitInfoApi
      .checkProjectGitRepsBranch(this.formData.projectId!, value, field)
      .then(respose => {
        if (!respose.data) {
          callback(
            new Error(
              `项目git仓库不是空的，为了不丢失团队已经写好的代码，请先手工创建分支${value}，并merge好代码再执行生成任务`
            )
          )
        } else {
          callback()
        }
      })
  }
}
