
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import GenerateCode from './generate-code.vue'
import CodeGenerateHistoryDataList from './history-table.vue'

/**
 * 代码生成页面
 */
@Component({
  name: 'codeGenerate',
  components: { GenerateCode, CodeGenerateHistoryDataList }
})
export default class CodeGenerate extends Vue {

}
