
import BaseTablePanel from '@/frame/share/table-panel'
import { Component } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import tableConfig from './history-table-config'
import { PagerQueryBean, PageListData, PageDataApiResult } from '@/libs/http-request'
import _ from 'lodash'
import codeGenerateTaskApi, { CodeGenerateTask } from '@/api/code-generate/code-generate-task.ts'
import { PageInfo } from '@/libs/api-request'
import { getterUserInfo } from '@/store'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  dateRage: [undefined, undefined],
  sorts: ['-createTime'],
  ownTeam: getterUserInfo().activedTeamId
}

@Component({
  name: 'teamRequestHistoryRecordDataList',
  components: { ExpendSearchPanel, TablePanel },
  filters: {
    // 图标显示切换
    staticon: (value: number) => {
      if (value === 1) {
        return 'iconfont iconok icon_agree font_size12'
      } else if (value === 0) {
        return ''
      } else {
        return 'iconfont iconclose_red icon_reject font_size12'
      }
    }
  }

})
export default class CodeGenerateHistoryDataList extends BaseTablePanel<CodeGenerateTask> {
  // 当前状态
  loading=false
  /// 表格定义
  tableConfig=tableConfig

  selectDataItems: Array<CodeGenerateTask> = []

  self: any = this

  /// 查询数据
  queryData: PagerQueryBean=_.cloneDeep(defaultQuery)

  /**
   * 页面数据
   */
  pageData: PageListData<CodeGenerateTask>={
    items: [],
    total: 0,
    pageSize: 10
  }

  /**
   * 加载数据
   */
  mounted () {
    this.$nextTick(() => {
      this.doPullDataList()
    })
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)

    if (!_.isEqual(newQuery, this.queryData)) {
      this.$nextTick(() => {
        this.queryData = newQuery
        this.doPullDataList()
      })
    }
  }

  /**
   * 页面数据拉取
   */
  protected doPullDataList (pageInfo?: PageInfo) {
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    const that = this

    this.pullDataList(pageInfo).then(response => {
      that.pageData = response;
      (that.$refs.tablePanel as any).setCurPage(pageInfo?.curPage)
    })
  }

  /**
   * 工具栏按钮事件
   */
  protected doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
  }

  /**
   * 从服务器拉取数据
   */
  protected onPullDataListFromServer (pagerQueryBean: PagerQueryBean): Promise<PageDataApiResult<CodeGenerateTask>> {
    return codeGenerateTaskApi.query(pagerQueryBean)
  }

  /**
   * 全部选中 或 单条选中
   * 列表选中的行
   */
  onSelectionChange (rows: Array<CodeGenerateTask>) {
    this.selectDataItems = rows
  }

  /**
   * 时间范围改变事件
   */
  onDateRangeChange (value: Array<string>) {
    this.queryData.dateRage = value
  }

  /**
   * 批量删除
   */
  deleteBatchRows () {
    if (this.selectDataItems.length === 0) {
      this.$Notice.warning({
        title: '提示',
        desc: '请选择您要删除记录'
      })
      return
    }
    this.$Modal.confirm(
      {
        title: '提示',
        content: '已选中' + this.selectDataItems.length + '条数据,确定要删除吗？',
        onOk: () => {
          const ids: Array<number> = this.selectDataItems.map(item => {
            return item!.id!
          })
          this.deleteData(ids)
        }
      }
    )
  }

  deleteRow (row: CodeGenerateTask, index: number) {
    this.$Modal.confirm(
      {
        title: '提示',
        content: '确定要删除吗？',
        onOk: () => {
          this.deleteData([row.id!])
        }
      }
    )
  }

  deleteData (ids: Array<number>) {
    codeGenerateTaskApi.deleteItemByIds(ids)
      .then(res => {
        this.$Notice.success({ title: '提示', desc: '删除成功' })
        this.doPullDataList()
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '删除记录失败',
          desc: err.message
        })
      })
      .finally(() => this.finishLoading())
  }
}

