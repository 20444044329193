import DefaultApiService, { BaseHasCreatorAndTimeEntity } from '@/libs/api-request'
import { getterUserToken } from '@/store'
import { EventSourcePolyfill } from 'event-source-polyfill'
import _ from 'lodash'

/**
 * 代码生成任务api接口
 */
export interface CodeGenerateTask extends BaseHasCreatorAndTimeEntity {
    // 任务名称"
    name?: string;
    // 工程ID
    projectId?: number;
    // 版本UUID
    versionUuid?: string;
    // 任务类型
    taskType?: string;
    // 任务状态
    status?: number;
    // 任务信息
    message?: string;
    // 开始时间
    startTime?: string;
    // 结束时间
    finishTime?: string;
 }

/**
  * 代码生成任务请求
  */
export interface CodeGenerRequest{
  // 工程id不能小于1"
  projectId: number;
  // 版本id
  versionId: number;
  // 分支
  branch: string;
  // 前端分支
  frontBranch: string;
 }

/**
  * 任务状态
  */
export interface TaskStatus {
  totalStep: number;
  curStep: number;
  taskId: number;
  message: string;
  finish: boolean;
  error: boolean;
 }

/**
 * 团队服务请求api接口
 */
class CodeGenerateTaskApi extends DefaultApiService<CodeGenerateTask> {
  constructor () {
    super('/api/codegeneratetask')
  }

  /**
   * 发生代码生成任务
   * @param data
   * @returns
   */
  requestCodeGenerTask (data: CodeGenerRequest,
    onMessage: (data: TaskStatus) => void,
    onOpen: () => void,
    onError: (err: Error) => void) {
    // 在请求中添加token信息
    const token = getterUserToken()

    const es = new EventSourcePolyfill(`/code/codegener/requesttask/${data.projectId}/${data.versionId}/${data.branch}/${data.frontBranch}`, {
      headers: {
        authorization: 'Bearer ' + token
      }
    })

    es.onopen = onOpen
    es.onmessage = (resp: any) => {
      const data = resp.data ? JSON.parse(resp.data) : undefined
      // 如果是心跳则跳过
      if (data.heartbeat) return
      onMessage(data as TaskStatus)
    }
    es.onerror = (err: any) => {
      if (!err.error) {
        onError(new Error('联结已经关闭'))
      } else {
        onError(new Error(`发生错误,代码为${err.status},${err.statusText}`))
      }
      es.close()
    }

    return es
  }
}

export default new CodeGenerateTaskApi()
